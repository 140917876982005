import React from 'react'
import clsx from 'clsx'
import styles from '../../pages/home.module.scss'
import { getListServiceType, getListDepartments, getListBranchs } from '~/services/apis/requests'
import _ from 'lodash'
import { logger } from '~/helpers/logger'
export const ComplaintModalContainer = () => {
  return (
    <>
      <hr></hr>
      <div className={clsx(styles.text, styles.marginTopDefault)}>
        <b>Bước 1: Khách hàng khiếu nại</b>
      </div>
      <p>
        Khách hàng (KH) yêu cầu, phản ánh, khiếu nại về các vấn đề liên quan đến
        sản phẩm, dịch vụ hoặc cán bộ nhân viên (CBNV) của VIB trên các kênh
        tiếp nhận khiếu nại.
      </p>
      <div className={clsx(styles.text, styles.marginTopDefault)}>
        <b>Bước 2: VIB tiếp nhận và phân loại khiếu nại</b>
      </div>
      <p>
        - Tiếp nhận thông tin, thực hiện xác thực KH.
        <br />
        - Tìm hiểu sự việc; xác định vấn đề khách hàng khiếu nại và thu thập hồ
        sơ khiếu nại.
        <br />
        - Thông báo đến KH đã tiếp nhận thông tin KH khiếu nại (trong vòng 07
        ngày làm việc kể từ ngày tiếp nhận).
        <br />
        - Phân loại sản phẩm/dịch vụ phản ánh và mức độ ưu tiên xử lý.
        <br />- Thực hiện chuyển tiếp phản ánh đến đơn vị trực tiếp liên quan
        (nếu có) hoặc chuyển tiếp khiếu nại đến đầu mối xử lý của Bộ phận nghiệp
        vụ có liên quan để phối hợp xử lý và theo dõi tiến độ.
      </p>
      <div className={clsx(styles.text, styles.marginTopDefault)}>
        <b>Bước 3: Xử lý thông tin và xác định nguyên nhân</b>
      </div>
      <p>
        - Rà soát, đối chiếu thông tin KH cung cấp với thông tin KH trên hệ
        thống; với quy định/quy trình của sản phẩm/dịch vụ và giải trình của các
        cá nhân, bộ phận liên quan.
        <br />
        - Liên hệ KH hoặc tổ chức các buổi làm việc KH để làm rõ thêm thông tin
        (nếu cần).
        <br />
        - Chuyển thông tin tới Phòng/Ban hỗ trợ để thực hiện kiểm tra, điều tra
        thông tin để đưa ra nhận định/sơ bộ về khiếu nại của KH.
        <br />- Trường hợp có các nghi vấn sai phạm liên quan đến nghiệp vụ,
        chuyển yêu cầu điều tra nội bộ.
      </p>
      <div className={clsx(styles.text, styles.marginTopDefault)}>
        <b>Bước 4: Xử lý khiếu nại</b>
      </div>
      <p>
        - Xây dựng giải pháp để xử lý khiếu nại và xin ý kiến của các phòng
        ban/bộ phận liên quan (nếu cần).
        <br />
        - Trao đổi hoặc tổ chức các buổi làm việc với khách hàng (nếu cần) để
        đàm phán và thống nhất về giải pháp xử lý khiếu nại.
        <br />
        - Thực hiện giải quyết khiếu nại theo giải pháp đã thống nhất giữa VIB
        và với KH.
        <br />- Thông báo kết quả xử lý cho đơn vị tiếp nhận khiếu nại (nếu đơn
        vị tiếp nhận khác đơn vị xử lý)
      </p>
      <div className={clsx(styles.text, styles.marginTopDefault)}>
        <b>Bước 5: Phản hồi và đóng khiếu nại</b>
      </div>
      <p>
        - Thông báo hoặc phản hồi kết quả xử lý khiếu nại cho KH.
        <br />
        - Tiếp nhận đánh giá về sự hài lòng của KH với phương án giải quyết
        khiếu nại và thực hiện lại bước 4 (nếu cần).
        <br />- Cập nhật kết quả giải quyết khiếu nại và đóng khiếu nại.
      </p>
      <hr></hr>
    </>
  )
}

export async function fetchDepartments() {
  try {
    const response = await getListDepartments()
    const statusCode: number = _.get(response, 'RESULT.CODE', -1)
    const DATA = _.get(response, 'OBJRETURN.data', [])
    if (statusCode === 0) {
      return DATA.map((item: any) => ({
        value: item.id,
        label: item.name,
      }))
    }
  } catch (error) {
    logger.error('Error fetching departments:', error)
  }
  return []
}

export async function fetchServiceTypes() {
  try {
    const response = await getListServiceType();
    const statusCode: number = _.get(response, "RESULT.CODE", -1);
    const DATA = _.get(response, "OBJRETURN.data", []);
    if (statusCode === 0) {
      return DATA.map((item: any) => ({
        value: item.id,
        label: item.type_vn,
        }));
    }
  } catch (error) {
    logger.error("Error fetching service types:", error);
  }
  return [];
}
function formatText(text: string) {
  return text.toLowerCase().split(' ').map((word, index) => {
      if (index === 0) {
          if (word === 'vib') return word.toUpperCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');
}
export async function fetchListBranch() {
  try {
    const response = await getListBranchs()
    const DATA = _.get(response, 'OBJRETURN.data', [])
    const statusCode: number = _.get(response, 'RESULT.CODE', -1)

    if (statusCode === 0) {
      return DATA.map((item: any) => ({
        value: item.code,
        label: formatText(item.name)
      }))
    }
  } catch (error) {
    logger.error('Error fetching service types:', error)
  }
  return []
}