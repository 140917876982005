import React, { useState } from 'react'
import styles from './InputComponent.module.scss'

interface InputComponentProps {
  type: string
  label: string
  required: boolean
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const InputComponent: React.FC<InputComponentProps> = ({
  type,
  label,
  value,
  required,
  onChange,
}) => {
  const [inputFocused, setInputFocused] = useState(false)
  const handleFocus = () => setInputFocused(true)
  const handleBlur = () => setInputFocused(false)
  return (
    <div
      className={`${styles['relative']} ${styles['zZero']} ${styles['wFull']} ${styles['mb5']}`}
    >
      <input
        type={type}
        placeholder=" "
        // required={required} 
        className={`${styles['input']} ${styles['inputBorderGray']}`}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={onChange}
        value={value || ''}
        autoComplete='off'
      />
      <label
        className={`${styles['label']} ${
          inputFocused || value ? styles['labelFocus'] : ''
        }`}
      >
        {label}
      </label>
    </div>
  )
}

export default InputComponent
