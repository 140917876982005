import Head from 'next/head'
import { media } from '~/helpers/media'

type CommonHeadProps = {
  title?: string
  description?: string
  keywords?: string
  children?: any
  previewImg?: string
}

const CommonHead = ({
  title,
  description,
  keywords,
  children,
  previewImg,
}: CommonHeadProps) => {
  const previewImage = media(previewImg ?? '/meta.jpg')

  return (
    <Head>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:image" content={previewImage} />
      <meta property="og:image:url" content={previewImage} />
      <meta property="og:image:secure_url" content={previewImage} />
      <meta property="og:description" content={description} />
      <meta property="og:keywords" content={keywords} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={previewImage} />
      <meta name="description" content="VIB customer services" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"/>
      <link rel="icon" href="/images/logo-vib.svg" />
      {children}
    </Head>
  )
}

export default CommonHead
