/* eslint-disable react-hooks/rules-of-hooks */
import Logger from 'js-logger'

Logger.useDefaults({
  defaultLevel:
    process.env.NODE_ENV === 'development' ? Logger.DEBUG : Logger.INFO,
  formatter: function (messages, context) {
    const date = new Date().toTimeString().split(' ')[0]
    if (context.name) {
      messages.unshift(`[${context.name}]\n${date}:`)
    } else {
      messages.unshift(`${date}:`)
    }
  },
})

export const logger = Logger
