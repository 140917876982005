import clsx from 'clsx'
import { ContentContainer } from '../ContentContainer/ContentContainer'
import Image from '~/components/shared/image/Image'
import styles from './Footer.module.scss'
import { useAppSelector } from '~/store/hooks'
import { useDarkMode } from '~/hooks/useDarkMode'

export const Footer = ({ wrapperClassName = '' }) => {
  const { colorTheme } = useDarkMode()
  const isMobile = useAppSelector((state) => state.app.isMobile)

  return (
    <footer className={clsx(styles.base, wrapperClassName)}>
      <ContentContainer className={styles.containerMenu}>
        <div
          className={clsx(styles.main, {
            [styles.mobile]: isMobile,
          })}
        >
          <span style={{ fontSize: '11px' }}>
            © Bản quyền thuộc về Ngân Hàng Quốc Tế (VIB)
          </span>
        </div>
      </ContentContainer>
    </footer>
  )
}
