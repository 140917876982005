import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  Form,
  Button,
  Radio,
  Select,
  Input,
  Rate,
  RadioChangeEvent,
  FloatButton,
  Tooltip,
  Modal,
  message,
  ConfigProvider,
} from 'antd'
import { InfoCircleFilled } from '@ant-design/icons'
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3'
import _ from 'lodash'
import clsx from 'clsx'
import CommonHead from '~/components/shared/CommonHead/CommonHead'
import { Footer } from '@components/shared/footer/Footer'
import { ContentContainer } from '~/components/shared/ContentContainer/ContentContainer'
import { ContentFooterContainer } from '~/components/shared/ContentFooterContainer/ContentFooterContainer'
import {
  ComplaintModalContainer,
  fetchServiceTypes,
  fetchDepartments,
  fetchListBranch,
} from '../containers/CustomerServiceContainer/CustomerServiceContainer'
import { createRequest } from '~/services/apis/requests'
import styles from './home.module.scss'
import { useSearchParams } from 'next/navigation'
import InputComponent from '~/components/shared/InputComponent/InputComponent'
import { logger } from '~/helpers/logger'

export async function getServerSideProps(context: { query: any }) {
  const { query } = context
  return {
    props: {
      referral: query.referral || null,
      b: query.b || null,
    },
  }
}

const Home = () => {
  const router = useRouter()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [form] = Form.useForm()
  const { Option } = Select
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [listBranchsOptions, setListBranchsOptions] = useState<any>([])
  const [serviceTypesOptions, setServiceTypesOptions] = useState<any>([])
  const [departmentsOptions, setDepartmentsOptions] = useState<any>([])
  const [serviceValue, setServiceValue] = useState(null)
  const [selectedBranch, setSelectedBranch] = useState<any>(null)
  const [selectedBranchLabel, setSelectedBranchLabel] = useState<string>(
    'Vui lòng chọn chi nhánh',
  )
  const [ratingScore, setRatingScore] = useState(0)
  const [serviceTypesValue, setServiceTypesValue] = useState(null)
  const [referrer, setReferrer] = useState<string>('')
  const searchParams = useSearchParams()
  const [isShowBranchModal, setIsShowBranchModal] = useState(false)
  useEffect(() => {
    const referral = searchParams.get('referral')
    const b = searchParams.get('b')
    const ref = b === '001' ? 'QR code' : referral === 'web' ? 'Website' : ''
    setReferrer(ref)
    const fetchAndFormatData = async () => {
      const [listBranchsData, serviceTypesData, departmentsData] =
        await Promise.all([
          fetchListBranch(),
          fetchServiceTypes(),
          fetchDepartments(),
        ])
      const formatData = (data: any) =>
        data.map((option: any) => (
          <Option
            key={option.value}
            className={styles.text}
            value={option.value}
          >
            {option.label}
          </Option>
        ))
      setListBranchsOptions(listBranchsData)
      setServiceTypesOptions(formatData(serviceTypesData))
      setDepartmentsOptions(formatData(departmentsData))
      setIsShowBranchModal(true)
    }

    fetchAndFormatData()
  }, [])
  useEffect(() => {
    let scrollPosition = 0;
    if (isShowBranchModal) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollPosition}px`;
      document.body.style.width = '100%';
    } else {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isShowBranchModal]); 
  const handleShowBranchModal = () => {
    setIsShowBranchModal(true)
  }
  const handleCloseBranchModal = () => {
    setIsShowBranchModal(false)
  }
  const handleSelectBranch = (value: any, option: any) => {
    setSelectedBranchLabel(option.label)
    setSelectedBranch(value)
    setIsShowBranchModal(false)
  }

  const showModal = () => {
    setIsModalVisible(true)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const handleServiceTypeChange = (value: any) => {
    setServiceTypesValue(value)
  }

  const getReCaptchaToken = useCallback(async () => {
    if (!executeRecaptcha) {
      logger.error('Execute recaptcha not yet available')
      return null
    }
    const gReCaptchaToken = await executeRecaptcha('CustomerFeedback')
    return gReCaptchaToken
  }, [executeRecaptcha])

  const handleServiceValueChange = (e: RadioChangeEvent) => {
    setServiceValue(e.target.value)
  }

  const onFinish = async (values: any) => {
    if (selectedBranch == null) {
      message.open({
        type: 'warning',
        content: 'Xin vui lòng chọn chi nhánh',
      })
      return
    }
    if (
      ratingScore == 0 &&
      (serviceValue == 'evaluate' || serviceValue == null)
    ) {
      message.open({
        type: 'warning',
        content: 'Xin vui lòng đánh giá chất lượng dịch vụ',
      })
      return
    }
    setIsSubmiting(true)
    const formData = new FormData()
    // const gReCaptchaToken = await getReCaptchaToken()
    // if (gReCaptchaToken) {
    //   formData.append('gReCaptchaToken', gReCaptchaToken)
    // }
    formData.append('branch', selectedBranch)
    const valuesValid = _.pickBy(
      values,
      (value: any) => value !== undefined && value !== '',
    )
    Object.entries(valuesValid).forEach(([item, value]) => {
      formData.append(
        item,
        typeof value === 'boolean' ? Number(value).toString() : value,
      )
    })
    formData.append('referrer', referrer || '')
    try {
      const res = await createRequest(formData)
      const statusCode: number = _.get(res, 'RESULT.CODE', -1)
      if (statusCode !== 0) {
        message.open({
          type: 'warning',
          content: 'Có lỗi xảy ra, vui lòng thử lại',
        })
        return
      } else {
        router.push('/success')
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsSubmiting(false)
    }
  }

  return (
    <>
      <CommonHead
        title={'Trang chủ - Ngân hàng Quốc Tế VIB'}
        description={'Home page app'}
        previewImg={'images/logo-vib.svg'}
      />
      <ContentFooterContainer>
        <ContentContainer className={styles.formContainer}>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{ service: 'evaluate', rating_score: '0' }}
            scrollToFirstError={true}
          >
            <Button
              type="primary"
              className={styles.buttonLocation}
              onClick={handleShowBranchModal}
            >
              {selectedBranchLabel}
            </Button>
            <ConfigProvider
              theme={{ token: { motion: false } }}
            >
              <Modal
                title="Quý Khách vui lòng lựa chọn chi nhánh"
                open={isShowBranchModal}
                onCancel={handleCloseBranchModal}
                footer={null}
                width={1000}
                closable={selectedBranch ? true : false}
                maskClosable={selectedBranch ? true : false}
                style={{
                  top: '37%',
                  bottom: '60%',
                }}
              >
                <Select
                  showSearch
                  placeholder="Nhập để tìm chi nhánh"
                  options={(listBranchsOptions || []).map((branch: any) => ({
                    value: branch.value,
                    label: branch.label,
                  }))}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onSelect={handleSelectBranch}
                  listHeight={110}
                  style={{ width: '100%' }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownStyle={{ position: 'fixed' }}
                />
              </Modal>
            </ConfigProvider>

            <div className={clsx(styles.titleRequire, styles.paddingGroup)}>
              Quý khách vui lòng lựa chọn dịch vụ
            </div>
            <Form.Item
              name="service"
              className={clsx(
                styles.groupRadioContainer,
                styles.contentContainer,
              )}
              rules={[{ required: true, message: 'Vui lòng chọn Dịch Vụ' }]}
            >
              <Radio.Group
                className={clsx(styles.groupRadio)}
                onChange={handleServiceValueChange}
              >
                <Radio
                  className={clsx(styles.text, styles.paddingGroup)}
                  value="evaluate"
                  defaultChecked
                >
                  Đánh giá chất lượng dịch vụ
                </Radio>
                <Radio
                  className={clsx(styles.text, styles.paddingGroup)}
                  value="complaint"
                >
                  <span>Khiếu nại chất lượng dịch vụ </span>
                  <span style={{ color: '#5E5C62' }}>
                    <Tooltip
                      title="Quy trình xử lý khiếu nại"
                      mouseEnterDelay={0.1}
                      zIndex={0}
                    >
                      <InfoCircleFilled onClick={showModal} />
                    </Tooltip>
                    <Modal
                      title="Quy trình xử lý khiếu nại"
                      open={isModalVisible}
                      onCancel={handleCancel}
                      width={1000}
                      footer={[
                        <Button
                          key="buttonCancelTooltipModal"
                          type="primary"
                          style={{ backgroundColor: '#FDB913', color: 'black' }}
                          onClick={handleCancel}
                        >
                          Đóng
                        </Button>,
                      ]}
                    >
                      <ComplaintModalContainer />
                    </Modal>
                  </span>
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="department"
              className={clsx(styles.contentContainer)}
              rules={[{ required: true, message: 'Vui lòng chọn Bộ Phận' }]}
            >
              <Select placeholder="Quý khách vui lòng lựa chọn bộ phận">
                {departmentsOptions}
              </Select>
            </Form.Item>

            <div className={styles.marginTopDefault}>
              <span className={styles.title}>
                Xin vui lòng cung cấp thông tin cá nhân của Quý khách
              </span>
              <span className={styles.titleSecondary}>
                {' '}
                (Thông tin này được bảo mật)
              </span>
            </div>
            <div className={styles.marginTopDefault}>
              <Form.Item
                name="phone_customer"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập số điện thoại',
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <InputComponent
                  type="text"
                  label="Số điện thoại"
                  required={true}
                  value={''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {}}
                />
              </Form.Item>
              <Form.Item
                name="fullname_customer"
                rules={[{ required: true, message: 'Vui lòng nhập họ và tên' }]}
              >
                <InputComponent
                  type="text"
                  label="Họ và tên"
                  required={true}
                  value={''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {}}
                />
              </Form.Item>
              <Form.Item
                name="email_customer"
                rules={[
                  {
                    type: 'email',
                    message: 'Vui lòng nhập đúng định dạng email',
                  },
                ]}
              >
                <InputComponent
                  type="text"
                  label="Email"
                  required={false}
                  value={''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {}}
                />
              </Form.Item>
              <Form.Item
                name="id_customer"
                rules={[
                  {
                    required: false,
                    message: 'Vui lòng nhập đúng định dạng',
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <InputComponent
                  type="text"
                  label="CMND/CCCD/HC"
                  required={false}
                  value={''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {}}
                />
              </Form.Item>
              <Form.Item
                name="tktt_customer"
                rules={[
                  {
                    required: false,
                    message: 'Vui lòng nhập đúng định dạng',
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <InputComponent
                  type="text"
                  label="Số tài khoản thanh toán"
                  required={false}
                  value={''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {}}
                />
              </Form.Item>
            </div>
            {serviceValue !== 'complaint' ? (
              <>
                <div className={styles.title}>Đánh giá mức độ hài lòng</div>
                <Form.Item name="rating_score">
                  <Rate
                    className={styles.marginTopDefault}
                    onChange={(value) => setRatingScore(value)}
                    allowClear={false}
                    style={{ fontSize: 30 }}
                  />
                </Form.Item>
                <div className={styles.contentContainer}>
                  <Form.Item name="comment_customer">
                    <Input.TextArea
                      className={styles.marginTopDefault}
                      placeholder={
                        ratingScore <= 3 && ratingScore > 0
                          ? 'Quý khách đang chưa hài lòng về vấn đề gì khi sử dụng sản phẩm dịch vụ của VIB?'
                          : 'Quý khách vui lòng nhập góp ý chi tiết về dịch vụ của VIB (nếu có).'
                      }
                    />
                  </Form.Item>
                </div>
              </>
            ) : (
              <>
                <div className={clsx(styles.titleRequire)}>Khiếu nại</div>
                <Form.Item
                  name="service_types_id"
                  className={clsx(styles.contentContainer)}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn sản phẩm/dịch vụ',
                    },
                  ]}
                >
                  <Select
                    className={styles.marginTopDefault}
                    placeholder="Vui lòng lựa chọn sản phẩm/dịch vụ cần khiếu nại"
                    onChange={(value) => handleServiceTypeChange(value)}
                  >
                    {serviceTypesOptions}
                  </Select>
                </Form.Item>
                {serviceTypesValue === 7 && (
                  <>
                    <Form.Item
                      name="additional_comment_customer"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập nội dung khác',
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="Nhập nội dung khác"
                        className={styles.marginTopDefault}
                      />
                    </Form.Item>
                  </>
                )}
                <Form.Item
                  name="comment_customer"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập nội dung khiếu nại',
                    },
                  ]}
                >
                  <Input.TextArea
                    placeholder="Nhập nội dung khiếu nại"
                    className={styles.marginTopDefault}
                  />
                </Form.Item>
              </>
            )}
            <hr />
            <Form.Item
              className={clsx(
                styles.submitButtonContainer,
                styles.marginTopDefault,
              )}
            >
              <ConfigProvider>
                <Button
                  className={styles.title}
                  type="primary"
                  htmlType="submit"
                  loading={isSubmiting}
                >
                  Gửi
                </Button>
              </ConfigProvider>
            </Form.Item>
          </Form>
          <FloatButton.BackTop />
        </ContentContainer>
        <Footer />
      </ContentFooterContainer>
      <style jsx global>{`
        .grecaptcha-badge {
          visibility: hidden !important;
        }
      `}</style>
    </>
  )
}
const HomeCaptcha = () => {
  const reCaptchaKey: string = process.env.NEXT_PUBLIC_PUBLIC_KEY_CAPTCHA || ''
  return (
    <GoogleReCaptchaProvider language="vi" reCaptchaKey={reCaptchaKey}>
      <Home />
    </GoogleReCaptchaProvider>
  )
}
export default HomeCaptcha
