import clsx from 'clsx'
import style from './ContentFooterContainer.module.scss'

type ContentFooterContainerProps = {
  className?: string
  children?: any
}

export const ContentFooterContainer = ({
  className,
  children,
}: ContentFooterContainerProps) => {
  return (
    <div
      className={clsx(style.container, className)}
    >
      <div className={style.childrencontainer}>{children}</div>
    </div>
  )
}
